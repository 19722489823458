<template>
  <div class="section-zupus">
    <h5>Discover records from a Zupu (Family Tree Book)</h5>
    <img
      class="image lazyload"
      width="800"
      height="220"
      :data-src="$getAsset('/assets/services/zupus/zupu-preview-optimized_smaller.png')"
      alt="Zupu page"
    />
    <div class="bold">Family Tree Records (Zupus)</div>
    <p>
      Zupus are private documents compiled by families to record the names and details of ancestors. They can include
      several hundreds of years of family history, including: dates, names, accomplishments, but also surname origins,
      and migration histories.
    </p>
    <router-link :to="zupusSearch"
      >Search {{ count.toLocaleString('en') }} {{ clanName }} family tree books</router-link
    >
  </div>
</template>

<script>
import {TAB_ID_SOURCES} from '@common/utils/consts.search';
import {sourceTypeCategories} from '@common/utils/consts.source';

export default {
  props: {
    pinyin: String,
    clanName: String,
    count: [Number, String],
  },
  computed: {
    zupusSearch() {
      const query = {
        category_id: sourceTypeCategories.CATEGORY_ZUPUS,
        tab: TAB_ID_SOURCES,
        clan_name: this.clanName,
      };
      return {name: 'search-all-records', query};
    },
  },
};
</script>

<style lang="scss" scoped>
.image {
  object-fit: cover;
  min-height: 200px;
  display: block;
  margin-top: 18px;
  box-shadow: $box-shadow-light;
  border-radius: 4px;
}
.bold {
  margin-top: 16px;
}
</style>
