<template>
  <surname-details-page v-if="isWordChinese"></surname-details-page>
  <surname-variant-details-page v-else></surname-variant-details-page>
</template>

<script>
import {isChineseText} from '@common/utils/utils';

import SurnameDetailsPage from '@/components/modules/surnameDetails/SurnameDetailsPage';
import SurnameVariantDetailsPage from '@/components/modules/surnameVariantDetails/SurnameVariantDetailsPage';

export default {
  computed: {
    isWordChinese() {
      let word = this.$route.query.word;
      if (word && Array.isArray(word) && word.length >= 1) {
        word = word[0];
      }
      return isChineseText(word);
    },
  },
  components: {SurnameDetailsPage, SurnameVariantDetailsPage},
};
</script>

<style lang="scss" scoped></style>
