import {sourceTypeCategories} from '@common/utils/consts.source';

export default {
  getMetaInfo(vm) {
    const pinyin = vm.clanDetailedState ? vm.clanDetailedState.pinyin : '';
    const chinese = vm.clanDetailedState
      ? vm.clanDetailedState.name_hant && vm.clanDetailedState.name_hant !== vm.clanDetailedState.name_ch
        ? `${vm.clanDetailedState.name_ch} / ${vm.clanDetailedState.name_hant}`
        : vm.clanDetailedState.name_ch
      : vm.queryWord;
    const surname = `${pinyin} ${chinese}`.trim();
    let metaObj = {
      title: `${surname} Last Name Origins, Meaning, and Surname Distribution`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: `Explore the meaning and story behind the ${surname} name. See where ${surname}s live and come from, and discover ${surname} records.`,
        },
        {vmid: 'title', name: 'title', content: `${surname} Last Name Origins, Meaning and Surname Distribution`},
        {
          vmid: 'og:title',
          property: 'og:title',
          name: 'og:title',
          content: `${surname} Last Name Origins, Meaning and Surname Distribution`,
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
      ],
    };

    let clan_data = vm.clanDetailedState;

    if (clan_data && clan_data.clan_image_url) {
      metaObj.meta.push({
        vmid: 'og:image:secure_url',
        property: 'og:image:secure_url',
        content: clan_data.clan_image_url,
      });
      metaObj.meta.push({
        vmid: 'og:image',
        property: 'og:image',
        content: clan_data.clan_image_url,
      });
      metaObj.meta.push({
        vmid: 'image',
        name: 'image',
        property: 'image',
        content: clan_data.clan_image_url,
      });
      metaObj.meta.push({
        vmid: 'twitter:image',
        name: 'twitter:image',
        property: 'twitter:image',
        content: clan_data.clan_image_url,
      });
    }
    return metaObj;
  },
  getRouteQueryWord(vm) {
    const word = vm.$route.query.word;
    if (word && Array.isArray(word) && word.length > 0) {
      return word[0];
    }
    return word;
  },
  getMainTitle(vm) {
    if (vm.clanDetailedState) {
      const {name_hant, name_ch, pinyin} = vm.clanDetailedState;
      const chinese = name_hant && name_hant !== name_ch ? `${name_ch} / ${name_hant}` : name_ch;
      return `${pinyin} ${chinese} Surname Origins`;
    }
    return `${vm.queryWord} Surname Origins`;
  },
  getLinks(vm) {
    if (vm.clanDetailedState.external_links && vm.clanDetailedState.external_links.length) {
      return vm.clanDetailedState.external_links;
    }
    return [
      {
        title: '8 Things You Didn’t Know About Your Chinese Surname',
        full_link: 'https://www.mychinaroots.com/blog/genealogy/8-things-you-didnt-know-about-your-chinese-surname/',
        image_url: vm.$getAsset('/assets/services/script_wall_optimized2.jpg'),
      },
    ];
  },
  clearClanDetails(vm) {
    if (
      vm.clanDetailedState &&
      vm.clanDetailedState.name_ch !== vm.queryWord &&
      vm.clanDetailedState.name_hant !== vm.queryWord
    ) {
      vm.$store.commit('setClanDetailedState', null);
      vm.surnameNotFound = false;
    }
  },
  init(vm) {
    vm.$scrollTo({x: 0});
    vm.$store.dispatch('getClanDetailsAction', vm.queryWord).catch(err => {
      vm.surnameNotFound = true;
    });

    const searchMentionsPromise = vm.$store.dispatch('searchAllRecordsSilentAction', {
      surname: vm.queryWord,
      only_meta: true,
    });
    const searchSourcesPromise = vm.$store.dispatch('searchSourcesInBackgroundAction', {
      clan_name: vm.queryWord,
      category_id: sourceTypeCategories.CATEGORY_ZUPUS,
      only_meta: true,
    });
    Promise.all([searchMentionsPromise, searchSourcesPromise]).then(responses => {
      const meta = {...responses[0].meta, sourcesMeta: responses[1].meta};
      vm.searchRecordsMeta = {...meta, surname: vm.queryWord};
    });

    if (!vm.$store.getters.searchCategoriesListState.length) {
      vm.$store.dispatch('fetchSearchCategoriesListAction');
    }
  },
  getSectionRecordZupusCount(vm) {
    const sourcesMeta = vm.searchRecordsMeta.sourcesMeta;
    const meta =
      sourcesMeta && sourcesMeta.facets && sourcesMeta.facets.category_id
        ? sourcesMeta.facets.category_id[sourceTypeCategories.CATEGORY_ZUPUS]
        : null;
    return meta && meta.count ? meta.count : 0;
  },
};
