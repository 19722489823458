import {sourceTypeCategories} from '@common/utils/consts.source';

export default {
  getMetaInfo(vm) {
    const surname = vm.spellingDetailedState ? vm.spellingDetailedState.name : vm.queryWord;
    let title = vm.pageTitle;
    return {
      title: title,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: `Explore the meaning and story behind the ${surname} name. See where ${surname}s live and come from, and discover ${surname} records.`,
        },
        {vmid: 'title', name: 'title', content: title},
        {vmid: 'og:title', property: 'og:title', content: title},
      ],
    };
  },
  init(vm) {
    vm.$scrollTo({x: 0});
    vm.$store.dispatch('getSpellingDetailsAction', vm.queryWord).catch(err => {
      vm.surnameNotFound = true;
    });
    vm.$store.dispatch('searchClansSurnamesAction', {q: vm.queryWord});

    const searchMentionsPromise = vm.$store.dispatch('searchAllRecordsSilentAction', {
      surname: vm.queryWord,
      only_meta: true,
    });
    const searchSourcesPromise = vm.$store.dispatch('searchSourcesInBackgroundAction', {
      clan_name: vm.queryWord,
      category_id: sourceTypeCategories.CATEGORY_ZUPUS,
      only_meta: true,
    });
    Promise.all([searchMentionsPromise, searchSourcesPromise]).then(responses => {
      const meta = {...responses[0].meta, sourcesMeta: responses[1].meta};
      vm.searchRecordsMeta = {...meta, surname: vm.queryWord};
    });

    if (!vm.$store.getters.searchCategoriesListState.length) {
      vm.$store.dispatch('fetchSearchCategoriesListAction');
    }
  },
  clearClanVariantDetails(vm) {
    if (vm.spellingDetailedState && vm.spellingDetailedState.name !== vm.queryWord) {
      vm.$store.commit('setSurnameClansSearchListState', null);
      vm.$store.commit('setSpellingDetailedState', null);
      vm.surnameNotFound = false;
    }
  },
  getSectionRecordZupusCount(vm) {
    const sourcesMeta = vm.searchRecordsMeta.sourcesMeta;
    const meta =
      sourcesMeta && sourcesMeta.facets && sourcesMeta.facets.category_id
        ? sourcesMeta.facets.category_id[sourceTypeCategories.CATEGORY_ZUPUS]
        : null;
    return meta && meta.count ? meta.count : 0;
  },
  getRouteQueryWord(vm) {
    const word = vm.$route.query.word;
    if (word && Array.isArray(word) && word.length > 0) {
      return word[0];
    }
    return word;
  },
};
