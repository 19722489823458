<template>
  <div class="cta-subscription">
    <h1>
      The new, easy way to do <span class="highlighted">genealogy.<underline-image width="208" /></span>
    </h1>
    <div class="text-xl">
      Just build your family tree, and get record matches by email — <span>automatically</span>!
    </div>
    <div class="button-container">
      <subscription-plans-link class="subscription-plans-link" :label="buttonLabel"></subscription-plans-link>
    </div>
  </div>
</template>

<script>
import UnderlineImage from '@common/elements/icons/UnderlineImage';
import {mapGetters} from 'vuex';

import SubscriptionPlansLink from '@/components/common/buttons/SubscriptionPlansLink.vue';

export default {
  created() {
    if (!this.$store.getters.subscriptionPlansState.length) {
      this.$store.dispatch('fetchSubscriptionPlansAction');
    }
    if (this.$store.getters.userIsLoggedInState) {
      this.$store.dispatch('fetchSubscriptionsAction');
    }
  },
  computed: {
    ...mapGetters(['isTrialEligibleState', 'standardYearlyPlanState']),
    buttonLabel() {
      const trialDays = this.standardYearlyPlanState ? this.standardYearlyPlanState.trial_days : 0;
      return this.isTrialEligibleState && trialDays ? `Start Free ${trialDays} Day Trial` : 'Become a Member';
    },
  },
  components: {SubscriptionPlansLink, UnderlineImage},
  name: 'ctaSubscription',
};
</script>

<style scoped lang="scss">
.cta-subscription {
  padding: 70px 50px;
  background: $background-light;
  border-radius: 20px;
  margin: 80px 0;
  text-align: center;

  .text-xl {
    margin-top: 28px;
    color: $neutral-600;
    span {
      font-weight: 600;
    }
  }
  .button-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 32px;
    .subscription-plans-link {
      width: 100%;
      max-width: 315px;
    }
  }
  .highlighted {
    white-space: nowrap;
    position: relative;
    .underline-image {
      width: 80%;
      position: absolute;
      left: -10px;
      bottom: -22px;
    }
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    padding: 50px 30px;
  }
}
</style>
