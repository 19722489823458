<template>
  <div class="surname-details-page">
    <page-not-found v-if="surnameNotFound"></page-not-found>
    <div class="readable_content" v-else>
      <div class="title-wrapper">
        <h1>{{ mainTitle }}</h1>
        <share-url-button :route="shareRoute" :title="shareTitle" :track-params="shareTrackParams"></share-url-button>
      </div>

      <cta-unified-search
        :surname="queryWord"
        :alt-surname="pinyin"
        :paywall="true"
        :prefill-surname="false"
      ></cta-unified-search>

      <div class="surname-content-wrapper">
        <div class="surname-content">
          <h5>
            {{ surname }} Surname Character<span v-if="clanDetailedState && clanDetailedState.meaning"> & Meaning</span>
          </h5>
          <section-surname-characters v-if="!clanDetailedState" :simplified="queryWord"></section-surname-characters>
          <section-surname-characters
            v-else
            :simplified="surnameSimplified"
            :traditional="surnameTraditional"
          ></section-surname-characters>

          <loading-animated v-if="!clanDetailedState" class="meaning-loading"></loading-animated>
          <div v-if="clanDetailedState && clanDetailedState.meaning" class="meaning">
            <span class="meaning-label">Meaning:</span> {{ clanDetailedState.meaning }}
          </div>

          <h5 v-if="clanDetailedState && clanDetailedState.origins">{{ pinyin }} Surname Origins</h5>
          <!-- prettier-ignore -->
          <div class="origins" v-if="clanDetailedState && clanDetailedState.origins">{{ clanDetailedState.origins }}</div>
        </div>
      </div>

      <section-zupus
        v-if="sectionRecordZupusCount"
        :pinyin="pinyin"
        :clan-name="clanNameCh"
        :count="sectionRecordZupusCount"
        class="section"
      ></section-zupus>

      <section-records
        class="section"
        :pinyin="surname"
        :meta="searchRecordsMeta"
        :loading="searchCategoriesLoadingState"
        :surname="clanNameCh"
      ></section-records>

      <cta-subscription v-if="!isTemplate && !userIsSubscribedState"></cta-subscription>

      <section-places class="section" :pinyin="surname" :clan-id="clanId" :places="places"></section-places>

      <template v-if="clanDetailedState">
        <section-pronunciation-dialect
          class="section"
          :variations="variantsByDialect"
          :is-template="isTemplate"
        ></section-pronunciation-dialect>
        <section-spelling-country
          class="section"
          :variations="variantsByCountry"
          :is-template="isTemplate"
        ></section-spelling-country>

        <save-surname-block
          v-if="!userIsSharedState"
          class="section"
          :surname="pinyin"
          :surnames-to-save="surnamesToSave"
          :has-surname="hasChineseSurname"
        ></save-surname-block>
        <cta-block-compare-plans
          class="section"
          action="Dig Deeper"
          :title="comparePlansTitle"
          :track-title="comparePlansTrackTitle"
        ></cta-block-compare-plans>

        <section-external-links
          class="section"
          v-prerender-if="'external_links'"
          :links="links"
        ></section-external-links>
      </template>
      <mcr-loading-indicator v-else :loading="true"></mcr-loading-indicator>
      <surname-search-form-block class="section"></surname-search-form-block>
    </div>
  </div>
</template>

<script>
import ShareUrlButton from '@common/elements/buttons/ShareUrlButton';
import SurnameSearchFormBlock from '@common/elements/forms/SurnameSearchFormBlock';
import SectionExternalLinks from '@common/elements/layouts/SectionExternalLinks';
import LoadingAnimated from '@common/elements/loading/LoadingAnimated';
import SectionPlaces from '@common/pages/surnameDetails/SectionPlaces';
import SectionPronunciationDialect from '@common/pages/surnameDetails/SectionPronunciationDialect';
import SectionRecords from '@common/pages/surnameDetails/SectionRecords';
import SectionSpellingCountry from '@common/pages/surnameDetails/SectionSpellingCountry';
import SectionSurnameCharacters from '@common/pages/surnameDetails/SectionSurnameCharacters';
import SectionZupus from '@common/pages/surnameDetails/SectionZupus';
import surnameDetailsPage from '@common/pages/surnameDetails/js/surname-details-page';
import {isChineseText} from '@common/utils/utils';
import {getSurnameDetailRoute} from '@common/utils/utils.routes';
import {mapGetters} from 'vuex';

import CtaBlockComparePlans from '@/components/common/cta/ctaBlockComparePlans';

import SaveSurnameBlock from '@/components/modules/surnameDetails/common/SaveSurnameBlock';
import ctaSubscription from '@/components/modules/surnameDetails/common/ctaSubscription';
import CtaUnifiedSearch from '@/components/modules/surnameDetails/common/ctaUnifiedSearch';

import clanData from './prerender/clanData';

const PageNotFound = () => import('@/components/page.not.found');

export default {
  metaInfo() {
    return surnameDetailsPage.getMetaInfo(this);
  },
  created() {
    this.clearClanDetails();
    if (this.$route.meta.isPrerenderTemplate) {
      this.isTemplate = true;
      this.$store.commit('setClanDetailedState', clanData);
      return;
    }
    if (window.__PRERENDERED_STATE__) {
      this.$store.commit('setClanDetailedState', window.__PRERENDERED_STATE__);
      window.__PRERENDERED_STATE__ = null;
      return;
    }
    this.init();
    if (this.userIsLoggedInState) {
      this.$store.dispatch('fetchFamilyTreePersonAction', 'my');
    }
  },
  watch: {
    '$route.query': {
      handler: function (toQuery, fromQuery) {
        if (toQuery.word !== fromQuery.word) {
          this.clearClanDetails();
          this.init();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      isTemplate: false,
      surnameNotFound: false,
      searchRecordsMeta: {},
    };
  },
  computed: {
    ...mapGetters([
      'clanDetailedState',
      'clanDetailedLoadingState',
      'surnamesSearchFormTextState',
      'userIsLoggedInState',
      'familyTreePersonState',
      'familyTreePersonUpdateLoadingState',
      'userIsSharedState',
      'userIsSubscribedState',
      'searchCategoriesLoadingState',
    ]),
    queryWord() {
      return surnameDetailsPage.getRouteQueryWord(this);
    },
    mainTitle() {
      return surnameDetailsPage.getMainTitle(this);
    },
    surname() {
      return this.clanDetailedState ? this.pinyin : this.queryWord;
    },
    pinyin() {
      return this.clanDetailedState ? this.clanDetailedState.pinyin : '';
    },
    clanNameCh() {
      return this.clanDetailedState ? this.clanDetailedState.name_ch : null;
    },
    links() {
      return surnameDetailsPage.getLinks(this);
    },
    variantsByDialect() {
      return this.clanDetailedState.dialects.by_dialect;
    },
    variantsByCountry() {
      return this.clanDetailedState.dialects.by_country;
    },
    surnamesToSave() {
      if (!this.clanDetailedState) {
        return [this.queryWord];
      }
      let search = this.surnamesSearchFormTextState;
      const pinyin =
        search && (search === this.clanDetailedState.name_hant || search === this.clanDetailedState.name_ch)
          ? this.pinyin
          : this.allSpellings.includes(search)
          ? search
          : this.pinyin;
      return [...new Set([pinyin, this.clanDetailedState.name_hant || this.clanDetailedState.name_ch])];
    },
    allSpellings() {
      const dialects = this.variantsByDialect.map(item => item.spellings);
      const pronounce = this.variantsByCountry.map(item => item.spellings);
      return [...dialects, ...pronounce].flat();
    },
    hasChineseSurname() {
      return this.familyTreePersonState
        ? Boolean(this.familyTreePersonState.surnames.find(surname => isChineseText(surname.value)))
        : false;
    },
    clanId() {
      return this.clanDetailedState ? this.clanDetailedState.object_id : '';
    },
    places() {
      return this.clanDetailedState ? this.clanDetailedState.meta.places : [];
    },
    surnameSimplified() {
      return this.isTemplate ? this.clanDetailedState.name_ch_list : this.clanDetailedState.name_ch;
    },
    surnameTraditional() {
      return this.isTemplate ? this.clanDetailedState.name_hant_list : this.clanDetailedState.name_hant;
    },
    shareRoute() {
      return getSurnameDetailRoute(this.queryWord);
    },
    shareTitle() {
      return `Check out this page I found on the meaning and origins of the surname "${this.queryWord}": `;
    },
    shareTrackParams() {
      return {surname: this.queryWord};
    },
    comparePlansTitle() {
      return `What ${this.surname} family history discoveries await you?`;
    },
    comparePlansTrackTitle() {
      return this.comparePlansTitle.replace(this.surname, '[NAME]');
    },
    sectionRecordZupusCount() {
      if (this.isTemplate) {
        return 0;
      }
      return surnameDetailsPage.getSectionRecordZupusCount(this);
    },
  },
  methods: {
    init() {
      return surnameDetailsPage.init(this);
    },
    clearClanDetails() {
      return surnameDetailsPage.clearClanDetails(this);
    },
  },
  components: {
    ctaSubscription,
    ShareUrlButton,
    CtaUnifiedSearch,
    SectionSurnameCharacters,
    CtaBlockComparePlans,
    LoadingAnimated,
    SaveSurnameBlock,
    SectionExternalLinks,
    SectionSpellingCountry,
    SectionPronunciationDialect,
    SectionRecords,
    SectionPlaces,
    SurnameSearchFormBlock,
    SectionZupus,
    PageNotFound,
  },
};
</script>

<style lang="scss" scoped>
@import '~@common/pages/surnameDetails/styles/surname-details-page.scss';
</style>
